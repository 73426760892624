import React, { FunctionComponent, useEffect } from 'react'

import PuxTrumbowyg from '../components/shared/pux-trumbowyg'
import Layout from '../components/layout/layout'
import PuxMetaTags from '../components/shared/PuxMetaTags'
import '../sass/errorPage/error-page.scss'
import { PuxErrorPage } from '../types/pux.types'
import { ReactComponent as PuxIllustration } from '../img/warning-404.svg'
import PuxIllustrationBG from '../img/x-404.svg'

const NotFoundPage: FunctionComponent<PuxErrorPage> = ({ pageContext }) => {
  useEffect(() => {
    if (dataLayer) {
      dataLayer.push({ event: `error`, action: `404` })
    }
  }, [])

  return (
    <Layout>
      <PuxMetaTags title="404: Stránka nenalezena" />
      <div
        className="error-page-background"
        style={{
          backgroundImage: `url(${PuxIllustrationBG})`,
        }}
      >
        <div className="pux-container">
          <div className="error-page">
            <div className="error-page-content">
              <PuxTrumbowyg content={pageContext.content} />
            </div>
            <div className="error-page-illustration">
              <PuxIllustration />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
